@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply p-0; /* For tailwind Dialog styling override */
}

body {
  color: #2d3748;
  min-width: 320px;
}

/* This style corresponds to
 * the footer component due to
 * a bug in TailwindCSS not
 * recognizing template columns
 * with more than 4 columns.
 * see components/commercetools-ui/footer/index.tsx
 * This is a hardcoded and messy
 * workaround.
 */
@media (min-width: 768px) {
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

.select-accent {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9J25vbmUnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggc3Ryb2tlPScjY2UzZTcyJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMS41JyBkPSdNNiA4bDQgNCA0LTQnLz48L3N2Zz4K');
}

@layer base {
  html {
    font-family: 'Open Sans';
  }
}

.isPinkBulletin {
  background-color: #e1bab6;
}

.pageMaxWidth {
  margin: 0 auto;
  max-width: 1876px;
}

.isMobilePagePadding {
  padding-inline: 16px;
}

@layer utilities {
  .relative-width {
    width: 100%;
    left: 0;
    right: 0;
    margin-left: 0px;
    margin-right: 0px;
  }

  .fixed-screen-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  .markdown {
    @apply max-w-none overflow-x-hidden;
    @apply mx-auto pb-10 text-neutral-800 prose-h1:text-neutral-800 prose-h2:text-neutral-800 prose-h3:text-neutral-800 prose-h4:text-neutral-800 lg:prose-lg;
    @apply prose-a:text-accent-600 prose-blockquote:text-neutral-700;
    @apply prose-code:rounded prose-code:bg-neutral-200 prose-code:p-1 prose-code:text-accent-600 prose-pre:bg-neutral-200;
    @apply prose-img:rounded-sm;
    @apply prose-strong:font-bold prose-strong:text-neutral-800;
    @apply prose-ol:list-decimal;
    @apply prose-ul:list-disc;
    @apply prose-li:text-neutral-800;
  }
}
